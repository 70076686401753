const REDIRECT_URL_SESSION_KEY = "redirectTarget";
const USER_ALREADY_ASSOCIATED_WITH_PROVIDER_KEY = "userAlreadyAssociatedWithProvider";
const CUSTOM_STATE_FAILURE_KEY = "customStateFailure";
const LOGISTICS_API_PARTNER_AUTH_STATE = "logisticsApiPartnerAuthState";
const LOGISTICS_API_PARTNER_NAME = "logisticsApiPartnerName";
const ACCOUNT_NOT_READY = "accountNotReady";
const ONLY_FLEXPORT_ADMIN_CAN_SIGNUP = "onlyFlexportAdminCanSignUp";

interface SessionStorageHandler {
  setter: (value: string) => void;
  getter: () => string | null;
  clearer: () => void;
}

function createSessionStorageHandler(key: string): SessionStorageHandler {
  const setter = (value: string) => sessionStorage.setItem(key, value);
  const getter = () => sessionStorage.getItem(key);
  const clearer = () => sessionStorage.removeItem(key);

  return { setter, getter, clearer };
}

export const {
  setter: setSessionRedirectTarget,
  getter: getSessionRedirectTarget,
  clearer: clearSessionRedirectTarget,
} = createSessionStorageHandler(REDIRECT_URL_SESSION_KEY);

export const {
  setter: setUserAlreadyAssociatedWithProvider,
  getter: getUserAlreadyAssociatedWithProvider,
  clearer: clearUserAlreadyAssociatedWithProvider,
} = createSessionStorageHandler(USER_ALREADY_ASSOCIATED_WITH_PROVIDER_KEY);

export const {
  setter: setCustomStateFailure,
  getter: getCustomStateFailure,
  clearer: clearCustomStateFailure,
} = createSessionStorageHandler(CUSTOM_STATE_FAILURE_KEY);

export const {
  setter: setLogisticsApiPartnerAuthState,
  getter: getLogisticsApiPartnerAuthState,
  clearer: clearLogisticsApiPartnerAuthState,
} = createSessionStorageHandler(LOGISTICS_API_PARTNER_AUTH_STATE);

export const {
  setter: setPartnerName,
  getter: getPartnerName,
  clearer: clearPartnerName,
} = createSessionStorageHandler(LOGISTICS_API_PARTNER_NAME);

export const {
  setter: setAccountNotReady,
  getter: getAccountNotReady,
  clearer: clearAccountNotReady,
} = createSessionStorageHandler(ACCOUNT_NOT_READY);

export const {
  setter: setOnlyFlexportAdminCanSignUp,
  getter: getOnlyFlexportAdminCanSignUp,
  clearer: clearOnlyFlexportAdminCanSignUp,
} = createSessionStorageHandler(ONLY_FLEXPORT_ADMIN_CAN_SIGNUP);
