import { I18n } from "aws-amplify";

import TranslationKeys from "config/i18n";

export enum CognitoErrorCode {
  UserNotFound = "UserNotFoundException",
  NotAuthorizedException = "NotAuthorizedException",
  CodeMismatchException = "CodeMismatchException",
}

export enum SellerServiceErrorCode {
  UserNotFound = "USER_NOT_FOUND",
  EmailSignupNotAllowed = "EMAIL_SIGNUP_NOT_ALLOWED",
  AccountNotReady = "ACCOUNT_NOT_READY",
  OnlyFlexportAdminCanSignUp = "ONLY_FLEXPORT_ADMIN_CAN_SIGNUP",
}

export const customError = {
  [CognitoErrorCode.UserNotFound]: I18n.get(TranslationKeys.ERROR_USER_NOT_FOUND),
  [CognitoErrorCode.NotAuthorizedException]: I18n.get(TranslationKeys.ERROR_USER_NOT_FOUND),
  [CognitoErrorCode.CodeMismatchException]: I18n.get(TranslationKeys.ERROR_CODE_MISMATCH),
  [SellerServiceErrorCode.UserNotFound]: I18n.get(TranslationKeys.ERROR_USER_NOT_FOUND),
  [SellerServiceErrorCode.EmailSignupNotAllowed]: I18n.get(TranslationKeys.ERROR_EMAIL_SIGNUP_NOT_ALLOWED),
  [SellerServiceErrorCode.AccountNotReady]: I18n.get(TranslationKeys.ERROR_ACCOUNT_NOT_READY),
  [SellerServiceErrorCode.OnlyFlexportAdminCanSignUp]: I18n.get(TranslationKeys.ERROR_ONLY_FLEXPORT_ADMIN_CAN_SIGNUP),
};
