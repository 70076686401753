// see more in https://aws-amplify.github.io/docs/js/i18n
export enum TranslationKeys {
  // NAVBAR
  NAVBAR_LOGIN = "Log In",

  // SIDEBAR TEASER
  SIDEBAR_TEASER_TITLE1 = "Ship Anywhere",
  SIDEBAR_TEASER_TITLE2 = "Sell Everywhere",
  SIDEBAR_TEASER_TITLE3 = "Grow Faster",
  SIDEBAR_TEASER_DESCRIPTION1 = "Simplify the process of moving your products from factory floor to customer door. Gain access to versatile shipping solutions, ensuring your products remain consistently available, in-stock, and ready to sell.",
  SIDEBAR_TEASER_DESCRIPTION2 = "Reach customers both online and in-person, providing easy access and timely deliveries to meet them wherever they are. We integrate with the largest retail marketplaces so you can import your catalog with a single click.",
  SIDEBAR_TEASER_DESCRIPTION3 = "Supercharge your business from initial sale to full scale with hassle-free logistics solutions that grow with you, empowering you to consistently deliver exceptional customer experiences.",

  REVOLUTION_TITLE = "Welcome to the Omnichannel Seller Portal!",
  SIGNUP_BUTTON = "Sign Up",
  RETURN_TO_LOGIN_LINK = "Return to login",
  SUBMIT_BUTTON = "Submit",
  WELCOME_TITLE = "Welcome back!",
  FORGOT_PASSWORD_TITLE = "Forgot password",
  SET_PASSWORD = "Set Password",
  EMAIL_LABEL = "Email",
  CODE_LABEL = "Verification Code",
  EMAIL_PLACEHOLDER = " Enter your email", //https://app.asana.com/0/1123273817757082/1126541508362699
  EMAIL_CODE_PLACEHOLDER = "Code that was sent by email",
  PASSWORD_LABEL = "Password",
  CONFIRM_PASSWORD_LABEL = "Confirm Password",
  NEW_PASSWORD_LABEL = "New Password",
  PASSWORD_PLACEHOLDER = "Must be 8 characters or more",
  CONFIRM_PASSWORD_PLACEHOLDER = "Password must match",
  FORGOT_PASSWORD_LINK = "Forgot Password?",
  LOGIN_BUTTON = "Login",
  BUTTON_FACEBOOK_LOGIN = "Login with Facebook",
  BUTTON_FACEBOOK_SIGNUP = "Sign up with Facebook",
  BUTTON_GOOGLE_LOGIN = "Login with Google",
  BUTTON_GOOGLE_SIGNUP = "Sign up with Google",
  BUTTON_SHOPIFY_LOGIN = "Login with Shopify",
  BUTTON_SHOPIFY_SIGNUP = "Sign up with Shopify",
  BUTTON_FLEXPORT_LOGIN = "Login with Flexport",
  CANCEL_BUTTON = "Cancel",
  ALLOW_BUTTON = "Allow",
  LOGOUT = "Log out",
  NOT_YOUR_ACCOUNT = "Not your account?",
  ALREADY_HAVE_AN_ACCOUNT = "Already have an account?",
  ALREADY_HAVE_AN_ACCOUNT_LOG_IN = "Log In",

  REVOLUTION_TEASER = "Connect your entire supply chain from freight through fulfillment with instant pricing and booking. It only takes two minutes to set up and no credit card required. Get started now!",
  SIGNUP_DATA = "We won’t post anything, your data is safe.",
  TOS_DESCRIPTION = "By submitting, I agree to the Flexport",
  TOS_LINK = "Terms of Service",
  PP_LINK = "Privacy Policy",
  CONFIRM = "Confirm",
  PASSWORD_RESET_DESCRIPTION = "Password reset is not available for accounts created via Facebook or Google. Please reset your password on those platforms.",
  PASSWORD_RESET_DESCRIPTION2 = "Password reset is not available for accounts created via Facebook, Google, or Shopify. Please reset your password on those platforms.",

  SMS_MFA_TITLE = "Enter the verification code",
  SMS_MFA_DESCRIPTION = "Enter the verification code sent to your phone ending in {phoneNumberEnding} to continue.",
  SMS_MFA_CONTACT_SUPPORT_PRE = "",
  SMS_MFA_CONTACT_SUPPORT = "Contact support",
  SMS_MFA_CONTACT_SUPPORT_POST = " if you are having problems logging in.",
  SMS_MFA_CODE = "Verification code",
  SMS_MFA_CODE_PLACEHOLDER = "000000",
  SMS_MFA_REMEMBER_DEVICE = "Trust this device (do not ask again for this code for 30 days)",
  SMS_MFA_CONTINUE = "Continue",
  SMS_MFA_RESEND_CODE = "Resend Code",

  // Consent page
  CONSENT_TITLE = "{partnerName} wants to access your Flexport account",
  CONSENT_DESCRIPTION = "By allowing access, {partnerName} will have:",
  CONSENT_ACCESS_SCOPE = "Access to create, edit and delete product, inventory, order and return information",
  CONSENT_ACCESS_CUSTOMER_INFORMATION = "Access to customer information",
  CONSENT_AGREEMENT = "By clicking Allow, you allow this app and Flexport to use and exchange your information in accordance with their respective terms of service and privacy policies. You can change or update this integration at any time in the Seller Portal.",

  // Error messages
  ERROR_GENERIC = "Unexpected Error",
  ERROR_ACCESS_DENIED = "This email is already associated with a Seller Portal account. Please log in below instead of using Facebook or Google.",
  ERROR_ACCESS_DENIED2 = "This email is already associated with a Seller Portal account. Please log in below instead of using Facebook, Google, or Shopify.",
  ERROR_USER_NOT_FOUND = "The email or password you have entered is incorrect.",
  ERROR_REQUIRED = "This field is required",
  ERROR_GREATER_THAN_8 = "Must be at least 8 characters.",
  ERROR_NOT_A_NUMBER = "Not a valid number",
  ERROR_INVALID_EMAIL = "Not a valid email. ex. test@example.com",
  ERROR_FLEXPORT_EMAIL = "Please sign up with this email address using your Google account.",
  ERROR_EXPIRED_FORGOT_PASSWORD_LINK = "The link has expired. Please request a new forgot password link.",
  ERROR_EXPIRED_VERIFICATION_CODE = "The verification code has expired. Please request a new code.",
  ERROR_PASSWORDS_MATCH = "Confirm password does not match with password",
  ERROR_CODE_MISMATCH = "Code is invalid",
  ERROR_SMS_MFA_NOT_AUTHORIZED = "Access code expired. Please login again.",
  ERROR_USER_TEMPORARILY_LOCKED = "Too many invalid attempts. Please try again later.",
  ERROR_ACCOUNT_ALREADY_EXISTS = "An account with the given email already exists.",
  ERROR_WRONG_PROVIDER = "This account was created with {provider}. Please log in with {provider}.",
  ERROR_EMAIL_SIGNUP_NOT_ALLOWED = "Please log in using Shopify, Google, or Facebook instead.",
  ERROR_ACCOUNT_NOT_READY = "The account is getting migrated. Please check back in a few days.",
  ERROR_CUSTOM_STATE_FAILURE = "There was an error logging in. Please try again.",
  ERROR_ONLY_FLEXPORT_ADMIN_CAN_SIGNUP = "Sign-up is restricted to admins only. Please contact your admin for access.",

  PROVIDER_GOOGLE = "Google",
  PROVIDER_FACEBOOK = "Facebook",
  PROVIDER_SHOPIFY = "Shopify",
  PROVIDER_EMAIL = "email and password",
  PROVIDER_UNKNOWN = "another login method",
}

export default TranslationKeys;
